/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import Input from "../common/Input/Input";
import Button from "../common/ButtonV2/Button";
import { getReferralFromCookie, clearReferralCookie } from "../../utils/referral";
import axios from "axios";

// Common passwords for validation
const commonPasswords = [
  "123456", "password", "123456789", "12345678", "12345", "1234567", "qwerty",
  "abc123", "111111", "123123"
];

// Password validation function
const validatePassword = (password, userAttributes = {}) => {
  const errors = [];
  const { username, email } = userAttributes;

  // UserAttributeSimilarityValidator
  if (username && password.toLowerCase().includes(username.toLowerCase())) {
    errors.push("Password is too similar to your username.");
  }
  if (email && password.toLowerCase().includes(email.toLowerCase().split("@")[0])) {
    errors.push("Password is too similar to your email.");
  }

  // MinimumLengthValidator
  if (password.length < 8) {
    errors.push("Password must be at least 8 characters long.");
  }

  // CommonPasswordValidator
  if (commonPasswords.includes(password)) {
    errors.push("Password is too common.");
  }

  // NumericPasswordValidator
  if (!Number.isNaN(password)) {
    errors.push("Password cannot be entirely numeric.");
  }

  return errors;
};

const RegisterForm = ({ onSwitchToLogin, setIsLoading }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [validate, setValidate] = useState({});
  const [apiErrors, setApiErrors] = useState({});

  const handlePasswordChange = (value) => {
    setPassword(value);
    const passwordErrors = validatePassword(value, { username: displayName, email });
    setValidate((prev) => ({
      ...prev,
      password: passwordErrors.length > 0 ? passwordErrors : null,
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const normalizedEmail = email.toLowerCase();

    let validateTemp = {};
    if (validator.isEmpty(displayName)) {
      validateTemp.username = "This field can't be empty";
    }

    if (validator.isEmpty(normalizedEmail)) {
      validateTemp.email = "This field can't be empty";
    }

    if (!validator.isEmail(normalizedEmail)) {
      validateTemp.email = "Email is not valid";
    }

    const passwordErrors = validatePassword(password, { username: displayName, email });
    if (passwordErrors.length > 0) {
      validateTemp.password = passwordErrors;
    }

    if (validator.isEmpty(rePassword)) {
      validateTemp.rePassword = "This field can't be empty";
    } else if (password !== rePassword) {
      validateTemp.rePassword = "Password not match!";
    }

    setValidate({ ...validateTemp });
    if (Object.keys(validateTemp).length > 0) return; // Prevent submission if there are validation errors

    try {
      setIsLoading(true);
      setApiErrors({});

      const referralCode = getReferralFromCookie();

      const response = await axios.post("/api/auth/register", {
        password,
        password_confirm: rePassword,
        username: displayName,
        email: normalizedEmail,
        referrer_id: referralCode,
      });

      if (response.status === 201) {
        clearReferralCookie();

        try {
          const url = new URL(window.location.href);
          url.searchParams.delete("ref");
          window.history.replaceState({}, "", url.toString());
        } catch (error) {
          console.error("Error clearing URL parameter:", error);
        }
        toast.success("Account created! Please login!");
        onSwitchToLogin();

        if (window.fbq) {
          fbq("track", "CompleteRegistration", {
            status: true,
            email: normalizedEmail,
          });
        } else {
          console.log("Facebook pixel not found (window.fbq is not available)");
        }
      } else {
        setApiErrors(response.data);
      }
    } catch (err) {
      console.error(err);
      setApiErrors({ non_field_errors: [err.message] });
    } finally {
      setIsLoading(false);
    }
  }

  const getFieldErrors = (field) =>
    validate[field] ? Array.isArray(validate[field]) ? validate[field] : [validate[field]] : apiErrors[field] || [];

  return (
    <>
      <h1 className="mb-6 font-header text-5xl font-medium uppercase text-white">
        Create an <span className="text-red-500">Account</span>
      </h1>
      <form className="mb-4 flex flex-col gap-4" onSubmit={handleSubmit}>
        {apiErrors.non_field_errors && (
          <ul className="list-disc pl-4 text-red-500">
            {apiErrors.non_field_errors.map((err, index) => (
              <li key={index}>{err}</li>
            ))}
          </ul>
        )}

        <div>
          <p className="mb-2 text-white/60">User Name</p>
          <Input
            type="text"
            name="custom-display-name"
            autoComplete="off"
            placeholder="Enter your display name"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            error={getFieldErrors("username")[0]}
            data-lpignore="true"
            data-form-type="other"
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Email</p>
          <Input
            type="email"
            name="email"
            autoComplete="email"
            placeholder="Enter your email"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={getFieldErrors("email")[0]}
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Password</p>
          <Input
            type="password"
            name="password"
            autoComplete="new-password"
            placeholder="Enter a password"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
            error={getFieldErrors("password").map((err, index) => (
              <p key={index} className="mt-1 text-red-500">{err}</p>
            ))}
          />
        </div>

        <div>
          <p className="mb-2 text-white/60">Confirm Password</p>
          <Input
            type="password"
            name="password_confirm"
            autoComplete="new-password"
            placeholder="Confirm your password"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
            error={getFieldErrors("rePassword")[0]}
          />
        </div>

        <div className="mb-4 text-center text-xs text-white/30">
          By clicking on Create Account you agree to our{" "}
          <a
            href="/terms"
            target="_blank"
            className="cursor-pointer text-accent hover:underline"
          >
            Terms of Service.
          </a>
        </div>
        <Button
          buttonType="submit"
          type="accent-background-text-black"
          className="font-bold"
        >
          Create Account
        </Button>
      </form>
      <div className="flex flex-col items-center gap-2">
        <p className="text-[#AEAFB1]">
          Already have an account?{" "}
          <button
            className="cursor-pointer text-accent hover:underline"
            onClick={onSwitchToLogin}
          >
            Log in
          </button>
        </p>
      </div>
    </>
  );
};

export default RegisterForm;
