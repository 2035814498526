import React from "react";
import BasePage from "./BasePageV2";

export const basePageWrap = (Component) => (props) => (
  <BasePage {...props} _class={Component.name}>
    <Component {...props} />
  </BasePage>
);

export default BasePage;
