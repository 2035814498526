import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Menu, Transition } from "@headlessui/react";
import Pf from "../../public/img/pf.jpg";
import logout from "../../api/client/auth";

import Button from "../common/ButtonV2/Button";
import NavBarCollapse from "../common/NavBarCollapse/NavBarCollapse";
import { NAVIGATION, ROUTE } from "../../const";
import AuthModal from "../AuthModal/AuthModal";
import withStaffCheck from "../../hoc/withStaffCheck";
import { ExitFullScreenIcon } from "@radix-ui/react-icons";


const NavBar = ({ isLoggedIn, userInfo, isStaff }) => {

  const router = useRouter();
  const [navigation] = useState(NAVIGATION);
  const [isNavigationOpen, setIsNavigationOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);

  useEffect(() => {
    // Listen for auth:required events
    const handleAuthRequired = () => {
      setShowLoginModal(true);
    };

    window.addEventListener('auth:required', handleAuthRequired);
    return () => window.removeEventListener('auth:required', handleAuthRequired);
  }, []);

  // Clear showLoginModal after successful login
  useEffect(() => {
    if (isLoggedIn && showLoginModal) {
      setShowLoginModal(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // Check for login parameter in URL
    const { login } = router.query;
    if (login && !isLoggedIn) {
      setShowLoginModal(true);
    }
  }, [router.query, isLoggedIn]);

  // Clear login parameter after successful login
  useEffect(() => {
    if (isLoggedIn && router.query.login) {
      const url = new URL(window.location.href);
      url.searchParams.delete('login');
      window.history.replaceState({}, '', url.toString());
    }
  }, [isLoggedIn, router.query]);

  return (
    <div className="sticky top-0 z-30">
      <div className="mx-auto bg-darkBg-700 py-4">
        <div className="container flex w-full items-center justify-between gap-3 lg:justify-start lg:gap-10">
          <Link className="cursor-pointer" href={ROUTE.HOME}>
            <Image
              src="/img/FightRealmLogo.png"
              width={200}
              height={30}
              className="-mt-1"
              alt="fightRealmLogo"
            />
          </Link>
          <NavBarCollapse
            className="lg:hidden"
            isOpen={isNavigationOpen}
            onClick={() => setIsNavigationOpen(!isNavigationOpen)}
          ></NavBarCollapse>

          {/* desktop view */}
          <div className="hidden items-center gap-8 lg:flex">
            {navigation.map((el) => (
              <Link
                className="text-white hover:underline"
                href={el.href}
                key={el.name}
              >
                {el.name}
              </Link>
            ))}
          </div>

          <div className="hidden lg:ml-auto lg:block">
            {isLoggedIn ? (
              <div className="relative">
                <Menu>
                  <Menu.Button className="group relative flex cursor-pointer items-center gap-2">
                    <Image
                      src={userInfo?.profile_image || Pf}
                      className="h-10 w-10 rounded-full border"
                      width={40}
                      height={40}
                      alt="profilePicture"
                    ></Image>
                    <div className="text-base font-semibold text-white group-hover:underline">
                      <span>{userInfo?.username}</span>
                    </div>
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="text-white"
                    />
                  </Menu.Button>
                  <Transition>
                    <Menu.Items className="absolute right-0 mt-2 w-[200px] rounded-md bg-neutral-900 shadow-lg ring-1 ring-black/5 focus:outline-none">
                      <div className="px-1 py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? "bg-neutral-700" : ""
                                } group flex w-full items-center rounded-md px-3 py-2 text-sm text-white transition-all`}
                              onClick={() => router.push(ROUTE.SETTING)}
                            >
                              My Profile
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? "bg-neutral-700" : ""
                                } group flex w-full items-center rounded-md px-3 py-2 text-sm text-white transition-all`}
                              onClick={() => router.push(ROUTE.INVITE_FRIENDS)}
                            >
                              Invite Friends
                            </button>
                          )}
                        </Menu.Item>
                        {isStaff !== null && isStaff !== undefined && isStaff &&(
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={`${active ? "bg-neutral-700" : ""} group flex w-full items-center rounded-md px-3 py-2 text-sm text-white transition-all`}
                                onClick={() => router.push(ROUTE.GENERATE_QR)}
                              >
                                Generate QR
                              </button>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? "bg-neutral-700" : ""
                                } group flex w-full items-center rounded-md px-3 py-2 text-sm text-red-500 transition-all`}
                              onClick={logout}
                            >
                              Logout
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            ) : (
              <div className="flex items-center gap-9">
                <AuthModal
                  defaultView="login"
                  trigger={
                    <button className="text-white hover:underline">
                      Log in
                    </button>
                  }
                />
                <AuthModal
                  defaultView="register"
                  trigger={
                    <button className="ext-black rounded-md bg-accent px-4 py-2 font-semibold">
                      Sign up
                    </button>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div
        className={isNavigationOpen ? "w-full bg-neutral-900 py-2" : "hidden"}
      >
        <ul className="flex flex-col rounded-lg border border-neutral-900 bg-neutral-900">
          {navigation.map((el) => (
            <li key={el.name}>
              <Link
                href={el.href}
                onClick={() => setIsNavigationOpen(false)}
                className={"block rounded-lg px-5 py-5 text-white"}
              >
                {el.name}
              </Link>
            </li>
          ))}
        </ul>
        {isLoggedIn ? (
          <div className="mx-8 mt-5 flex flex-col gap-5 border-t border-solid border-gray-700 py-5">
            <Button
              className="w-full text-base font-bold text-white"
              onClick={() => {
                setIsNavigationOpen(false); // Close navigation
                router.push(ROUTE.SETTING); // Navigate to settings
              }}
            >
              My Profile
            </Button>
            <Button
              className="w-full text-base font-bold text-white"
              onClick={() => {
                setIsNavigationOpen(false); // Close navigation 
                router.push(ROUTE.INVITE_FRIENDS); // Navigate to Invite Friends
              }}

            >
              Invite Friends
            </Button>
            <Button className="w-full text-base text-red-500" onClick={logout}>
              Logout
            </Button>
          </div>
        ) : (
          <div className="mx-8 mt-5 flex flex-col gap-5 border-t border-solid border-gray-700 py-5">
            <AuthModal
              defaultView="login"
              trigger={
                <Button className="w-full text-base font-bold text-white">
                  Log in
                </Button>
              }
            />
            <AuthModal
              defaultView="register"
              trigger={
                <Button
                  type="accent-background-text-black"
                  className="w-full text-base font-bold"
                >
                  Sign up
                </Button>
              }
            />
          </div>
        )}
      </div>
      <AuthModal
        defaultView="login"
        autoOpen={showLoginModal}
        onAutoOpenComplete={() => setShowLoginModal(false)}
        trigger={<div />} // Empty trigger since we're controlling it programmatically
      />
    </div>
  );
};

NavBar.propTypes = {
  isLoggedIn: PropTypes.bool,
  userName: PropTypes.string,
};

NavBar.defaultProps = {
  isLoggedIn: false,
};

export default withStaffCheck(NavBar);
