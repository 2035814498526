import React from "react";

const Input = ({
  inputStyle,
  wrapperStyle,
  placeholder,
  onChange,
  type,
  error,
  name,
}) => {
  // Check if error should be displayed
  const shouldDisplayError =
    error && !(Array.isArray(error) && error.length === 0);

  return (
    <div className="relative">
      <div
        className={`${wrapperStyle} ${shouldDisplayError ? "border border-solid border-red-500" : ""}`}
      >
        <input
          type={type}
          name={name}
          className={inputStyle}
          placeholder={placeholder}
          onChange={onChange}
        ></input>
      </div>
      {shouldDisplayError && (
        <div className="relative text-red-500">{error}</div>
      )}
    </div>
  );
};

export default Input;
