export const ROUTE = {
  HOME: "/",
  SETTING: "/settings",
  INVITE_FRIENDS: "/invite-friends",
  GENERATE_QR: "/generate-qr",
  UNAUTHORIZED: "/",
  CURRENT_COMP: "/competition/fight-realm-001-the-launch/",
  CURRENT_EVENT: "/event/35d82b2c8037cc1/",
  WHATSAPP: "https://chat.whatsapp.com/EfM2cBzWbdEBGo7W4qeGFc",
};

export const QUERY_KEY = {
  EVENTS: "events",
  EVENT: "event",
  PROFILE: "profile",
  COMPETITION: "competition",
};

export const ACCESS_TOKEN = "JWT_TOKEN";

export const NAVIGATION = [
  { name: "Fantasy", href: "/competition/fightrealm-001:-the-launch/" },
  { name: "Leaderboard", href: "/leaderboard" },
  { name: "Events", href: "/events" },
  { name: "Articles", href: "/articles" },
];

export const API_URL =
  process.env.NEXT_PUBLIC_API_URL || process.env.DATA_API_URL;
export const WAGTAIL_API_URL =
  process.env.NEXT_PUBLIC_WAGTAIL_API_URL || process.env.WAGTAIL_API_URL;
