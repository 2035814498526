/**
 * Sets the referral cookie with proper validation and encoding
 * @param {string} referralCode - The referral code to store
 * @returns {boolean} - Success status of setting cookie
 */
export function setReferralCookie(referralCode) {
  try {
    // Validation
    if (referralCode && (!isValidReferralCode(referralCode) || referralCode.length > 50)) {
      console.error('Invalid referral code format or length');
      return false;
    }

    // Set or clear cookie
    if (referralCode) {
      const expiryDate = new Date();
      expiryDate.setDate(expiryDate.getDate() + 30);
      const encodedCode = encodeURIComponent(referralCode);
      document.cookie = `referralCode=${encodedCode}; expires=${expiryDate.toUTCString()}; path=/; SameSite=Strict`;
    } else {
      clearReferralCookie();
    }
    return true;
  } catch (error) {
    console.error('Error setting referral cookie:', error);
    return false;
  }
}

/**
 * Gets the referral code from URL with validation
 * @returns {string|null} - Validated referral code or null
 */
export function getReferralFromUrl() {
  try {
    if (typeof window === 'undefined') return null;
    
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('ref');
    
    return isValidReferralCode(code) ? code : null;
  } catch (error) {
    console.error('Error getting referral from URL:', error);
    return null;
  }
}

/**
 * Gets the stored referral code from cookie
 * @returns {string|null} - Decoded referral code or null
 */
export function getReferralFromCookie() {
  try {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('referralCode='))
      ?.split('=')[1];

    if (!cookieValue) return null;

    const decodedCode = decodeURIComponent(cookieValue);
    return isValidReferralCode(decodedCode) ? decodedCode : null;
  } catch (error) {
    console.error('Error reading referral cookie:', error);
    return null;
  }
}

/**
 * Clears the referral cookie
 */
export function clearReferralCookie() {
  document.cookie = "referralCode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

/**
 * Validates referral code format
 * @param {string} code - The referral code to validate
 * @returns {boolean} - Whether the code is valid
 */
function isValidReferralCode(code) {
  return code && typeof code === 'string' && /^[a-zA-Z0-9]+$/.test(code);
} 