import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getCookie } from '../utils/cookie';
import { toast } from 'react-hot-toast';

const useAuthRedirect = () => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    // Skip redirect if the URL has ?login=true
    const { query } = router;
    if (query.login === 'true') {
      return;
    }

    // Check for localStorage items
    const accessToken = localStorage.getItem('JWT_TOKEN');
    const localStorageUser = localStorage.getItem('currentUser');
    const cookieUser = getCookie('currentUser');

    // Skip checks during profile updates
    const isProfileUpdate = router.pathname === '/settings' || 
                          router.pathname.startsWith('/api/profile');
    if (isProfileUpdate) {
      return; // Exit early for profile updates
    }

    // Only redirect if both localStorage items are missing or both cookies are missing
    const isLocalStorageValid = accessToken && localStorageUser;
    const areCookiesMissing = !cookieUser;

    if (isLocalStorageValid && areCookiesMissing) {
      // Clear local storage
      localStorage.removeItem('JWT_TOKEN');
      localStorage.removeItem('currentUser');

      // Notify user about session expiration
      toast.error('Your session has expired. Please log in again.');

      // Redirect with login=true in the URL
      const url = new URL(window.location.href);
      url.searchParams.set('login', 'true');
      window.location.href = url.toString();
    }
  }, [router.isReady, router.query, router.pathname]);
};

export default useAuthRedirect;
