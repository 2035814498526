import React, { useEffect, useState } from "react";
import apiClient from "../utils/apiClient";
import { ACCESS_TOKEN } from "../const";

const withStaffCheck = (WrappedComponent) => {
  return (props) => {
    const [isStaff, setIsStaff] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
      const checkStaffStatus = async () => {
        // Check if user is authenticated first
        if (!localStorage.getItem(ACCESS_TOKEN)) {
          // console.log('👤 User not authenticated, skipping staff check');
          setIsStaff(false);
          setLoading(false);
          return;
        }

        try {
          const response = await apiClient.makeRequest(
            {
              method: 'GET',
              url: '/profile/',
            },
            {
              context: 'Staff permission check',
              feature: 'Authorization',
              endpoint: '/profile',
            }
          );

          setIsStaff(response?.is_staff ?? false);
        } catch (error) {
          if (error.response?.status === 401 || error.response?.status === 403) {
            // User is not logged in or unauthorized, assume not staff
            // console.log('🚫 User unauthorized, setting isStaff to false');
            setIsStaff(false);
          } else {
            // console.error("❌ Unexpected error in staff check:", error); // Leave error logging as it provides important context
            setIsStaff(false);
          }
        } finally {
          setLoading(false);
        }
      };

      checkStaffStatus();
    }, []);

    if (loading) {
      return null; // Or return a loading spinner/placeholder
    }

    return <WrappedComponent {...props} isStaff={isStaff} />;
  };
};

export default withStaffCheck;
