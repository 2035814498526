import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const NavBarCollapse = ({ onClick, className, isOpen }) => (
  <button
    onClick={onClick}
    data-collapse-toggle="navbar-default"
    type="button"
    className={`w-10 h-10 p-2 flex items-center justify-center rounded-lg focus:outline-none ${className}`}
    aria-controls="navbar-default"
    aria-expanded={isOpen}
  >
    <span className="sr-only">Open main menu</span>
    {!isOpen ? (
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
        className="w-6 h-6"
      >
        <path
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    ) : (
      <FontAwesomeIcon
        icon={faXmark}
        className="text-white text-2xl"
      />
    )}
  </button>
);

NavBarCollapse.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

NavBarCollapse.defaultProps = {
  onClick: () => {},
  className: "",
  isOpen: false,
};

export default NavBarCollapse;