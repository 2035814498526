import React, { useMemo } from "react";
import PropTypes from "prop-types";

const Button = ({
  children,
  type,
  className: classNameCustom,
  onClick,
  buttonType = "button",
}) => {
  const className = useMemo(() => {
    switch (type) {
      case "accent-background-text-black":
        return `uppercase text-sm font-semibold py-3 px-4 text-black bg-accent rounded-md ${classNameCustom}`;
      case "green-background-text-white":
        return `uppercase text-sm font-semibold py-2 px-4 text-white bg-blue-700 rounded-md ${classNameCustom}`;
      case "black-background-text-white":
        return `uppercase text-sm font-semibold py-2 px-4 text-white bg-darkBg-700 rounded-md border-gray-800 border ${classNameCustom}`;
      case "black-background-text-white-white-border":
        return `uppercase text-sm font-semibold py-2 px-4 text-white bg-darkBg-700 rounded-md border-white border ${classNameCustom}`;
      case "black-background-text-gray":
        return `uppercase text-sm font-semibold py-2 px-4 text-[#CECFD2] bg-[#1F242F] rounded-md ${classNameCustom}`;
      case "white-background-text-black":
        return `uppercase text-sm font-semibold text-black bg-white p-2 border border-solid border-black font-semibold ${classNameCustom}`;
      default:
        return ` ${classNameCustom}`;
    }
  }, [type, classNameCustom]);

  return (
    <button type={buttonType} className={className} onClick={onClick}>
      {children}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  buttonType: PropTypes.string,
};

Button.defaultProps = {};

export default Button;
