import React from "react";
import { z } from "zod";
import Input from "../common/Input/Input";
import Button from "../common/ButtonV2/Button";

const LoginSchema = z.object({
  email: z
    .string({ message: "Email is required" })
    .email({ message: "Invalid email address" })
    .min(1, { message: "Email is required" }),
  password: z
    .string({ message: "Password is required" })
    .min(1, { message: "Password is required" }),
});

const LoginForm = ({
  onSwitchToRegister,
  onSwitchToForgotPassword,
  onSubmit,
  errors,
  generalError,
}) => {
  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email").toLowerCase();
    const password = formData.get("password");

    const validationResult = LoginSchema.safeParse({ email, password });
    onSubmit(event, validationResult);
  }

  return (
    <>
      <h1 className="mb-6 font-header text-5xl font-medium uppercase text-white">
        Enter <span className="text-red-500">Fight Realm</span>
      </h1>
      <form onSubmit={handleSubmit} className="mb-4 flex flex-col gap-4">
        {generalError && <p className="text-red-500">{generalError}</p>}
        <div>
          <p className="mb-2 text-white/60">Email address</p>
          <Input
            type="email"
            name="email"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            placeholder="Enter email"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            error={errors?.email?.[0]}
            tabIndex={1}
          />
        </div>
        <div>
          <div className="mb-2 flex justify-between text-white/60">
            Password
          </div>
          <Input
            type="password"
            name="password"
            placeholder="Enter password"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
            error={errors?.password?.[0]}
            tabIndex={2}
          />
          <div className="mt-2 py-2">
            <button
              type="button"
              className="ml-auto text-sm text-accent hover:text-accent"
              onClick={onSwitchToForgotPassword}
              tabIndex={3}
            >
              Forgot your password?
            </button>
          </div>
        </div>

        <Button
          buttonType="submit"
          type="accent-background-text-black"
          className="font-bold"
          tabIndex={4}
        >
          Log In
        </Button>
      </form>
      <div className="flex flex-col items-center gap-2">
        <p className="text-[#AEAFB1]">
          New here?{" "}
          <button
            className="cursor-pointer text-accent hover:underline"
            onClick={onSwitchToRegister}
            tabIndex={5}
          >
            Create an account today!
          </button>
        </p>
      </div>
    </>
  );
};

export default LoginForm;
