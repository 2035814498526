import React from "react";
import { z } from "zod";
import Input from "../common/Input/Input";
import Button from "../common/ButtonV2/Button";

const ForgotPasswordSchema = z.object({
  email: z
    .string({ message: "Email is required" })
    .email({ message: "Invalid email address" }),
});

const ForgotPasswordForm = ({ onSwitchToLogin, onSubmit, error }) => {
  async function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");

    const validationResult = ForgotPasswordSchema.safeParse({ email });
    onSubmit(event, validationResult);
  }

  return (
    <>
      <h1 className="mb-6 font-header text-5xl font-medium uppercase text-white">
        Reset Password
      </h1>
      <form onSubmit={handleSubmit} className="mb-4 flex flex-col gap-4">
        {error && <p className="text-red-500">{error}</p>}
        <div>
          <p className="mb-2 text-white/60">Email</p>
          <Input
            type="email"
            name="email"
            inputStyle="bg-transparent outline-none w-full placeholder:text-neutral-500 text-white"
            placeholder="Enter your email"
            wrapperStyle="py-4 px-3 rounded-lg border border-solid border-[#3A3A41]"
          />
        </div>
        <Button
          buttonType="submit"
          type="accent-background-text-black"
          className="font-bold"
        >
          Reset Password
        </Button>
      </form>
      <div className="flex flex-col items-center gap-2">
        <p className="text-[#AEAFB1]">
          Remember your password?{" "}
          <button
            className="cursor-pointer text-accent hover:underline"
            onClick={onSwitchToLogin}
          >
            Log in
          </button>
        </p>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
