import React from "react";
import PropTypes from "prop-types";

const IconSpinner = (props) => {
  const { className, show } = props;
  const style = {
    width: "50px",
    height: "50px",
    stroke: "#ffffff",
    strokWidth: "4px",
    padding: "0px",
    margin: "auto",
  };
  return (
    <div
      className={`fixed left-0 top-0 z-20 h-full w-full items-center justify-between backdrop-blur-sm ${show ? "flex" : "hidden"}`}
    >
      <svg
        style={style}
        className={className}
        viewBox="0 0 30 30"
        preserveAspectRatio="xMidYMid"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="15" cy="15" r="12" fill="none" strokeLinecap="round">
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 15 15;180 15 15;720 15 15"
            keyTimes="0;0.5;1"
            dur="0.9s"
            begin="0s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-dasharray"
            calcMode="linear"
            values="9 56;46 14;9 56"
            keyTimes="0;0.5;1"
            dur="0.9s"
            begin="0s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  );
};

IconSpinner.propTypes = {
  className: PropTypes.string,
};

IconSpinner.defaultProps = {
  className: "",
};

export default IconSpinner;
