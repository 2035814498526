import axios from "axios";
import { ACCESS_TOKEN, API_URL } from "../const";

const api = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem(ACCESS_TOKEN);

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token ? `Token ${token}` : undefined;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle the 401 error
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem("currentUser");
    }
    return Promise.reject(error);
  },
);

export default api;
