import { ACCESS_TOKEN } from "../../const";
import apiClient from "../../utils/apiClient";

export default async function logout() {
  try {
    // Use apiClient to call the logout API with trailing slash
    await apiClient.makeRequest({
      method: 'POST',
      url: '/auth/logout/',
    }, {
      context: 'User logout',
      feature: 'Authentication'
    });

    // Clear local storage after successful logout
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem("currentUser");

    // Reload page to reset app state
    window.location.reload();
  } catch (error) {
    console.error("Logout error:", error);
    // Even if server logout fails, clear local storage and reload
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem("currentUser");
    window.location.reload();
  }
}
