import axios from 'axios';
import * as Sentry from '@sentry/nextjs';
import { ACCESS_TOKEN, API_URL } from '../const';
import { toast } from 'react-hot-toast';

class ApiClient {
  constructor(baseURL = API_URL) {
    // console.log('🔧 Initializing ApiClient with baseURL:', baseURL);
    this.client = axios.create({
      baseURL,
      timeout: 15000,
    });

    // Add request interceptor for authentication
    this.client.interceptors.request.use((config) => {
      const token = typeof window !== 'undefined' 
        ? localStorage.getItem(ACCESS_TOKEN) 
        : undefined;
      
      config.headers.Authorization = token ? `Token ${token}` : undefined;
      return config;
    });

    // Add response interceptor for auth errors
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401 || error.response?.status === 403) {
          // console.log('🔒 Unauthorized/Forbidden request, clearing auth data');
          if (typeof window !== 'undefined') {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem("currentUser");
            
            // Only handle for non-logout requests
            if (!error.config.url.includes('auth/logout')) {
              toast.error('Your session has expired. Please log in again.');
              // Add login parameter and reload
              const url = new URL(window.location.href);
              url.searchParams.set('login', 'true');
              window.location.href = url.toString();
            }
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async makeRequest(config, errorContext = {}) {
    // console.group('🌐 API Request Started:', config.method, config.url);
    // console.log('📝 Request Config:', {
    //   ...config,
    //   headers: config.headers,
    //   data: config.data
    // });

    try {
      // console.log('🚀 Making initial request (15s timeout)...');
      const startTime = typeof window !== 'undefined' ? performance.now() : 0;
      const response = await this.client(config);
      
      if (typeof window !== 'undefined') {
        const endTime = performance.now();
        // console.log('✅ Request successful:', {
        //   status: response.status,
        //   duration: `${(endTime - startTime).toFixed(2)}ms`,
        //   dataPreview: this.truncateData(response.data)
        // });
      }
      
      // console.groupEnd();
      return response.data;
    } catch (error) {
      // console.log('❌ Initial request failed, attempting error handling...');
      return this.handleError(error, config, errorContext);
    }
  }

  async handleError(error, config, errorContext) {
    // console.group('🔄 Error Handling Process');
    // console.log('📌 Original Error:', {
    //   message: error.message,
    //   status: error.response?.status,
    //   data: error.response?.data
    // });

    // Collect environment info safely
    const environmentInfo = typeof window !== 'undefined' ? {
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      language: window.navigator.language,
    } : {
      environment: 'server'
    };

    // Log first attempt failure
    Sentry.captureException(error, {
      extra: {
        ...errorContext,
        attempt: 1,
        config: {
          url: config.url,
          method: config.method,
          headers: config.headers,
          data: config.data,
        },
        response: {
          status: error.response?.status,
          headers: error.response?.headers,
          data: error.response?.data,
        },
        environment: environmentInfo,
      }
    });

    try {
      // console.log('🔄 Attempting retry with 30s timeout...');
      const retryResponse = await axios({
        ...config,
        timeout: 30000,
      });
      // console.log('✅ Retry successful');
      // console.groupEnd();
      return retryResponse.data;
    } catch (retryError) {
      return this.handleGeneralError(retryError, errorContext);
    }
  }

  async handle403Error(error, config, errorContext) {
    // console.group('🔒 403 Error Handler');
    try {
      // console.log('🔍 Checking profile accessibility...');
      const profileCheck = await this.client.get('/accounts/profile/');
      
      if (profileCheck.status === 200) {
        // console.log('✅ Profile is accessible, but original request was forbidden');
        Sentry.captureException(error, {
          extra: {
            ...errorContext,
            profileAccessible: true,
            originalError: '403 with valid session',
            config: {
              url: config.url,
              method: config.method,
            },
          }
        });
      }
    } catch (profileError) {
      // console.log('❌ Profile check failed - Session likely expired');
      await this.handleLogout();
      // console.groupEnd();
      throw new Error('SESSION_EXPIRED');
    }

    // console.log('⚠️ Original 403 error with valid session');
    // console.groupEnd();
    throw error;
  }

  async handleGeneralError(error, errorContext) {
    // console.group('❌ General Error Handler');
    // console.log('Error details:', {
    //   message: error.message,
    //   status: error.response?.status,
    //   data: error.response?.data
    // });

    Sentry.captureException(error, {
      extra: {
        ...errorContext,
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers,
        environment: {
          userAgent: navigator.userAgent,
          platform: navigator.platform,
          language: navigator.language,
        },
      }
    });
    
    await Sentry.flush(2000);
    // console.log('📤 Error logged to Sentry');
    // console.groupEnd();
    throw error;
  }

  async handleLogout() {
    // console.group('🔐 Logout Process');
    try {
      // console.log('🗑️ Clearing local storage...');
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem('currentUser');

      // console.log('📤 Calling logout endpoint...');
      await axios.post('/api/auth/logout');

      if (typeof window !== 'undefined') {
        // console.log('↪️ Redirecting to login page...');
        window.location.href = '/login';
      }
    } catch (error) {
      // console.error('❌ Logout error:', error); // Keep as error logging is needed
      Sentry.captureException(error, {
        extra: {
          context: 'Logout failed',
          error: error.message
        }
      });
      // Ensure cleanup even if API fails
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem('currentUser');
    }
    // console.groupEnd();
  }

  // Utility function to safely truncate response data for logging
  truncateData(data) {
    try {
      const stringified = JSON.stringify(data);
      return stringified.length > 200 
        ? stringified.substring(0, 200) + '...' 
        : stringified;
    } catch (e) {
      return 'Data cannot be stringified';
    }
  }
}

// Create singleton instance
const apiClient = new ApiClient();
// console.log('✨ ApiClient singleton instance created');

export default apiClient;
