import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import LoadingCircle from "../common/LoadingCircleFullPage";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { ACCESS_TOKEN } from "../../const";
import api from "../../api/axios";
import axios from "axios";

const AuthModal = ({ trigger, defaultView = "login", autoOpen, onAutoOpenComplete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState(defaultView);
  const [isLoading, setIsLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState({});
  const [loginGeneralError, setLoginGeneralError] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  useEffect(() => {
    if (autoOpen) {
      setIsOpen(true);
      if (onAutoOpenComplete) {
        onAutoOpenComplete();
      }
    }
  }, [autoOpen, onAutoOpenComplete]);

  const handleOpen = () => {
    setView(defaultView);
    setIsOpen(true);
  };

  async function handleLogin(event, validationResult) {
    setLoginErrors({});
    setLoginGeneralError("");

    if (!validationResult.success) {
      setLoginErrors(validationResult.error.flatten().fieldErrors);
      return;
    }

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    try {
      setIsLoading(true);
      const { data } = await api.post("/login/", {
        email: email.toLowerCase(),
        password,
      });

      // Get user profile and set storage
      localStorage.setItem(ACCESS_TOKEN, data.token);
      const { data: userInfo } = await api.get("/accounts/profile/");
      localStorage.setItem("currentUser", JSON.stringify(userInfo));

      // Set cookies
      await axios.post("/api/auth/set-token", {
        token: data.token,
        currentUser: userInfo,
      });

      toast.success("Login successful!");
      setIsOpen(false);  // Close modal after successful login
      window.location.reload();
    } catch (error) {
      console.error("Login error:", error);
      setLoginGeneralError(
        error.response?.data?.non_field_errors?.[0] ||
          error.response?.data?.error ||
          "An error occurred during login",
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function handleForgotPassword(event, validationResult) {
    // Clear previous error messages
    setForgotPasswordError("");
  
    // Validate the input
    if (!validationResult.success) {
      const errorMessage = validationResult.error.flatten().fieldErrors.email?.[0];
      setForgotPasswordError(errorMessage || "Invalid email address.");
      return;
    }
  
    // Extract email from the form
    const email = new FormData(event.currentTarget).get("email");
  
    try {
      setIsLoading(true);
  
      // Use the Axios `api` instance to send the request
      const response = await api.post("/profile/request-password-reset/", { email });
  
      // Check for a success response and notify the user
      if (response.data?.success) {
        toast.success("Password reset instructions sent to your email!");
        setView("login");
      } else {
        throw new Error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error("Password reset error:", error);
  
      // Handle server errors or provide a fallback message
      const errorMessage =
        error.response?.data?.error || "An error occurred. Please try again.";
      setForgotPasswordError(errorMessage);
    } finally {
      // Clear loading state
      setIsLoading(false);
    }
  }
  

  return (
    <>
      <div onClick={handleOpen}>{trigger}</div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        <DialogBackdrop className="fixed inset-0 bg-black/80" />
        <div className="fixed inset-0 w-screen overflow-y-auto p-4">
          <div className="flex min-h-full items-center justify-center">
            <DialogPanel className="relative w-full max-w-lg rounded-xl bg-black p-16 ring ring-[rgb(207,255,63)]">
              <button
                onClick={() => setIsOpen(false)}
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-300"
                aria-label="Close dialog"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              <LoadingCircle show={isLoading} />
              {view === "login" && (
                <LoginForm
                  onSwitchToRegister={() => setView("register")}
                  onSwitchToForgotPassword={() => setView("forgot-password")}
                  onSubmit={handleLogin}
                  errors={loginErrors}
                  generalError={loginGeneralError}
                />
              )}
              {view === "register" && (
                <RegisterForm
                  onSwitchToLogin={() => setView("login")}
                  setIsLoading={setIsLoading}
                />
              )}
              {view === "forgot-password" && (
                <ForgotPasswordForm
                  onSwitchToLogin={() => setView("login")}
                  onSubmit={handleForgotPassword}
                  error={forgotPasswordError}
                />
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AuthModal;
